.link {
   position: relative;
}

.link:hover {
    color: white;
}

.link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3.5px;
    bottom: 0;
    left: 0;
    background-color: #8cd16f;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.link:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }

  .listItems {
      padding-bottom: 3.5rem;
  }