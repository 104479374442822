.App:before {
   background-color: rgb(0, 0, 0);
   content: "";
   display: block;
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: hidden;
   margin: 0;
   z-index: -10;
   background-image: url("https://dl.dropboxusercontent.com/s/w6y37cq0g9axreu/hummingbird%20see%20through%20adapted%20for%20colored%20background.png?dl=0"); 
   background-position: center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

body {
    margin: 0;
    padding: 0;
}

