.flikering::before{
  content: 'Claudia Remy';
  animation: blink 5s linear infinite alternate;
}

@keyframes blink {
  0% {
    content: 'Claudia Remy';
  }
  50% {
    color: transparent;
  }
  100% {
    content: 'An evidential spiritual medium';
  }
}